@import url('https://fonts.googleapis.com/css?family=Exo:100,200,300,400,500,600,700');

html,
body, * {
  padding: 0;
  margin: 0;
  font-family: 'Exo';
  box-sizing: border-box;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 0px;
}

.App{
  width: 100vw;
  min-height: 100vh;
  position: relative;
}